export const APP_EDITOR_TOOLBAR = [
  [
    'bold',
    'italic'
  ],
  [
    'blockquote'
  ],
  [
    'underline',
    'strike'
  ],
  [
    'ordered_list',
    'bullet_list'
  ],
  [
    {
      'heading': [
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6'
      ]
    }
  ],
  [
    'link',
    'image'
  ],
  [
    'text_color',
    'background_color'
  ],
  // [
  //   'align_left',
  //   'align_center',
  //   'align_right',
  //   'align_justify'
  // ],
  [
    'format_clear'
  ]
]
