import { EditorState, NodeSelection } from 'prosemirror-state';
import { Command } from 'prosemirror-state';
import {Dispatch} from "@app/app-editor/ngx-editor/src/lib/commands/types";


export interface VariableAttrs {
  key: string;
  value: string;
  label: string;
  defaultValue?: string
}

export class VariableCommand {
  insert(editor, attrs: VariableAttrs): Command {
    return (state: EditorState, dispatch?: Dispatch): boolean => {
      const { schema, tr, selection } = state;

      const type = schema.nodes.variable;
      if (!type) {
        return false;
      }

      const variableAttrs = {
        ...attrs,
        id: Date.now()
      };

      dispatch(state.tr.replaceSelectionWith(schema.nodes.variable.create(variableAttrs)).scrollIntoView());
      return false;
    };
  }

  isActive(state: EditorState): boolean {
    const { selection } = state;
    if (selection instanceof NodeSelection) {
      return selection.node.type.name === 'variable';
    }

    return false;
  }

  getActiveItem(state, items) {
    let selected = null;
    const { selection } = state;
    if (selection instanceof NodeSelection && selection.node.type.name === 'variable') {
      const key = selection.node.attrs.key;
      return items.find(item => item.key === key) || null;
    }
  }

  canExecute(state) {
    return false;
  }
}

