import {NgModule} from '@angular/core';
import {MatIconModule} from "@angular/material/icon";
import {MatDividerModule} from "@angular/material/divider";
import {MatButtonModule} from "@angular/material/button";
import {FlexLayoutModule} from '@angular/flex-layout';
import {CommonModule} from "@angular/common";
import {MatExpansionModule} from "@angular/material/expansion";
import {ComposeAiModule} from "@widgets/gpt3-ui/compose-ai/compose-ai.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AppEditorComponent} from "@app/app-editor/app-editor.component";
import {VariableComponent} from "@app/app-editor/extensions/variable/variable.component";
import {MatSelectModule} from "@angular/material/select";
import {DropdownComponent} from "@app/app-editor/modules/dropdown/dropdown.component";
import { NgxEditorModule } from './ngx-editor/src/lib/editor.module';
import { MenuModule } from './ngx-editor/src/lib/modules/menu/menu.module';

@NgModule({
  declarations: [
    AppEditorComponent,
    VariableComponent,
    DropdownComponent,
  ],
  exports: [
    AppEditorComponent,
    DropdownComponent,
  ],
  imports: [
    CommonModule,
    MatSelectModule,
    FlexLayoutModule,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    MatExpansionModule,
    ComposeAiModule,
    NgxEditorModule,
    ReactiveFormsModule,
    FormsModule,
    MenuModule
  ]
})
export class AppEditorModule {
}
