import {nodes as basicNodes, marks} from './ngx-editor/schema';
import {Schema} from 'prosemirror-model';
import {node as codeMirrorNode} from 'prosemirror-codemirror-6';


const variableNode = {
  group: 'inline',
  content: 'inline*',
  inline: true,
  atom: true,
  draggable: true,
  type: 'variable',
  parseDOM: [
    {
      tag: 'span[class="preview-variable"]',
      getAttrs: dom => {
        debugger;
        let isVariableElem = dom.classList.contains("variable-elem");
        const key = dom.innerText;
        return isVariableElem && key ? { key } : false;
      }
    },
    {
      tag: 'span[class="variable-elem"]',
      getAttrs: dom => {
        let isVariableElem = dom.classList.contains("variable-elem");
        const key = dom.innerText.replace('{{', '').replace('}}', '');
        return isVariableElem && key ? { key } : false;
      }
    }
  ],
  toDOM: (node)=> {
    const attrs = node.attrs;
    const span = document.createElement('span');
    span.classList.add('variable-elem');
    span.innerHTML = `{{${attrs.key}}}`;
    return span;
  },
  attrs: {
    type: {
      default: 'variable'
    },
    label: {
      default: ''
    },
    key: {
      default: ''
    },
    defaultValue: {
      default: ''
    }
  }
}

const nodes = {
  ...basicNodes,
  // code_mirror: codeMirrorNode,
  variable: variableNode
};

export const schema = new Schema({
  nodes,
  marks,
});

