import { ApplicationRef, ComponentRef, createComponent, Injector } from '@angular/core';
import { Node as ProseMirrorNode } from 'prosemirror-model';
import { Plugin, PluginKey } from 'prosemirror-state';
import { EditorView, NodeView } from 'prosemirror-view';
import { Subscription } from 'rxjs';
import {VariableComponent} from "./variable.component";


class VariableView implements NodeView {
  dom: HTMLElement;
  view: EditorView;
  getPos: () => number;

  applicationRef: ApplicationRef;
  imageComponentRef: ComponentRef<VariableComponent>;
  resizeSubscription: Subscription;

  node: ProseMirrorNode;
  updating = false;

  constructor(node: ProseMirrorNode, view: EditorView, getPos: () => number, injector: Injector) {
    this.applicationRef = injector.get(ApplicationRef);

    // create component ref
    this.imageComponentRef = createComponent(VariableComponent, {
      environmentInjector: this.applicationRef.injector,
    });

    // Attach to the view so that the change detector knows to run
    this.applicationRef.attachView(this.imageComponentRef.hostView);

    this.setNodeAttributes(node.attrs);
    this.imageComponentRef.instance.view = view;

    this.dom = this.imageComponentRef.location.nativeElement;
    this.view = view;
    this.node = node;
    this.getPos = getPos;
    //
    // this.resizeSubscription = this.imageComponentRef.instance.imageResize.subscribe(() => {
    //   this.handleResize();
    // });
  }

  private computeChanges(prevAttrs: Record<string, any>, newAttrs: Record<string, any>): boolean {
    console.log('change', prevAttrs, newAttrs);
    return JSON.stringify(prevAttrs) === JSON.stringify(newAttrs);
  }

  private setNodeAttributes(attrs: Record<string, any>): void {
    this.imageComponentRef.instance.key = attrs['key'];
    this.imageComponentRef.instance.label = attrs['label'];
    this.imageComponentRef.instance.value = attrs['value'];
  }


  update(node: ProseMirrorNode): boolean {
    if (node.type !== this.node.type) {
      return false;
    }

    this.node = node;

    const changed = this.computeChanges(this.node.attrs, node.attrs);
    if (changed) {
      this.updating = true;
      this.setNodeAttributes(node.attrs);
      this.updating = false;
    }
    return true;
  }

  ignoreMutation(): boolean {
    return true;
  }

  selectNode(): void {
    this.imageComponentRef.instance.selected = true;
  }

  deselectNode(): void {
    this.imageComponentRef.instance.selected = false;
  }

  destroy(): void {
    this.applicationRef.detachView(this.imageComponentRef.hostView);
  }
}

const VariablePlugin = (injector: Injector): Plugin => {
  return new Plugin({
    key: new PluginKey('variable'),
    props: {
      nodeViews: {
        variable: (node: ProseMirrorNode, view: EditorView, getPos: () => number) => {
          return new VariableView(node, view, getPos, injector);
        },
      },
    },
  });
};

export default VariablePlugin;
