import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatButtonModule} from "@angular/material/button";
import {ReactiveFormsModule} from "@angular/forms";
import {LoadingButtonModule} from "@widgets/loading-button/loading-button.module";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {NgxCaptchaModule} from "ngx-captcha";
import {IconModule} from "@visurel/iconify-angular";
import {TrustHtmlModule} from "@app/shared/trust-html/trust-html.module";
import {MatListModule} from "@angular/material/list";
import {MagicBoxWidget} from "@widgets/magic-box/magic-box.widget";


@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        ReactiveFormsModule,
        LoadingButtonModule,
        MatSnackBarModule,
        NgxCaptchaModule,
        IconModule,
        TrustHtmlModule,
        MatListModule
    ],
  providers: [],
  declarations: [MagicBoxWidget],
  exports: [MagicBoxWidget]
})
export class MagicBoxWidgetModule { }
