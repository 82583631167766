import {Component, Input, OnInit} from '@angular/core';
import {EditorView} from "prosemirror-view";

@Component({
  selector: 'variable-view',
  template: `
    <div class="variable-node">
      {{key}}
    </div>
  `,
  styles: [`

  `]
})
export class VariableComponent implements OnInit {
  @Input() key: string;
  @Input() label = '';
  @Input() value = '';
  @Input() selected = false;
  @Input() view: EditorView;
  constructor() {

  }

  ngOnInit(): void {

  }

}
