import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {AuthService} from "@services/auth.service";
import {TextAiService} from "@services/text-ai.service";

import {FormGroup, FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import { schema } from "./schema";
import VariablePlugin from "./extensions/variable/variable.view";
import nodeViews from "./nodeviews";
import {VariableCommand} from "@app/app-editor/extensions/variable/variable.command";
import {APP_EDITOR_TOOLBAR} from "@app/app-editor/toolbar";
import {Editor, toDoc, toHTML, Validators} from './ngx-editor/src/public_api';
import {isString} from "@helper-functions/is-string";
import {MenuService} from "@app/app-editor/ngx-editor/src/lib/modules/menu/menu.service";
import {Popover} from "@widgets/popover/popover.service";
import {ComposeAiComponent} from "@widgets/gpt3-ui/compose-ai/compose-ai.component";
import {Overlay} from "@angular/cdk/overlay";


@Component({
  selector: 'app-editor',
  template: `
    <div #divElement fxLayout='row' fxLayoutAlign="center stretch">
      <ng-template #customMenu>
        <div class="variable-select">
          <app-dropdown [items]="variables">
          </app-dropdown>
        </div>
        <div class="alignment-select">
          <app-dropdown [iconMode]="true" [items]="alignments">
          </app-dropdown>
        </div>
        <a class="compose-btn" (click)="composeAi($event.currentTarget)">
          <img style="width: 100%" src="https://writi.io/wp-content/uploads/2021/12/feather.png">
        </a>
      </ng-template>
      <form [formGroup]="form">
        <div class="editor NgxEditor__Wrapper">
          <ngx-editor-menu [editor]="editor" [toolbar]="toolbar" [customMenuRef]="customMenu">
          </ngx-editor-menu>
          <ngx-editor *ngIf="editor" [editor]="editor" formControlName="editorContent">
            <ngx-editor-floating-menu [editor]="editor"></ngx-editor-floating-menu>
          </ngx-editor>
        </div>

      </form>
    </div>
  `,
  styles: [`
    :host {
      width: 100%;
      height: 100%;
    }
    ::ng-deep .ProseMirror.NgxEditor__Content {
      min-height: 400px;
    }
    .compose-btn {
      width: 20px;
      display: flex;
      align-items: center;
      margin-left: 7px;
    }
  `],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: AppEditorComponent
    }
  ]
})
export class AppEditorComponent implements OnInit, ControlValueAccessor  {
  composeConfig = {
    title: 'Compose',
    options: {
      appMinWidth: '320px',
      noHeader: false,
      hide: false,
      viewOnly: false,
      audience: false,
      tone: true,
      action: false,
      showTmp: false,
      templateSubjects: true,
      about: true,
      copy: false,
      showAdd: true,
      showGenerate: true,
    }
  }
  onChange = (v)=> {}
  form = new FormGroup({
    editorContent: new FormControl('', Validators.required(schema)),
  });
  @Input() variables = [{label: 'First name', value: 'firstName'}, {label: 'Last name', value: 'lastName'}]
  alignments = [
    {value: 'align_left', label: 'align_left'},
    {value: 'align_center', label: 'align_center'},
    {value: 'align_right', label: 'align_right'},
    {value: 'align_justify', label: 'align_justify'},
  ];
  toolbar: any = APP_EDITOR_TOOLBAR;
  editor: Editor;
  @ViewChild('divElement') divElement;
  constructor(private authService: AuthService,
              private popoverService: Popover,
              private menuService: MenuService,
              private textAiService: TextAiService,
              private injector: Injector,
              private overlay: Overlay) {
    const elem = toDoc('<span class="variable-elem">first name</span>', schema);
    console.log('tool', this.toolbar);
    // this.textAiService.suggestConnectionRequest(ConnectionRequest).subscribe();
    this.form.get('editorContent').valueChanges.subscribe((value)=> {
      const html = toHTML(value as any, schema); // -> html string
      console.log('v', value, html);
      this.onChange(html);
    })
  }

  ngOnInit(): void {
    this.editor = new Editor({
      schema,
      nodeViews,
      history: true,
      keyboardShortcuts: true,
      inputRules: true,
      attributes: { enterkeyhint: 'enter' },
      features: {
        linkOnPaste: true,
        resizeImage: true,
      },
    });
    this.editor.registerPlugin(VariablePlugin(this.injector));
    this.menuService.editor = this.editor;
  }

  insertNewVariable() {
    const item = {
      key: 'hello',
      value: 'okokoko',
      label: 'nice',
      defaultValue: '1234'
    }
    const command = new VariableCommand();
    const {state, dispatch} = this.editor.view;
    command.insert(this.editor.view, item as any)(state, dispatch);
  }

  registerOnTouched(fn: any): void {
  }

  registerOnChange( fn : any ) : void {
    this.onChange = fn;
  }

  change( $event ) {
    const html = toHTML(this.form.value.editorContent as any); // -> html string
    this.onChange(html);
  }

  writeValue(value: any): void {
    if(isString(value)) {
      const v = toDoc(value, schema);
      this.form.get('editorContent').patchValue(v as any)
    } else {
      this.form.get('editorContent').patchValue(value)
    }
  }

  composeAi(ref: any) {
    let pop: any = {};
    pop.ref = this.popoverService.open({
      // height: '500px',
      content: ComposeAiComponent,
      origin: this.divElement.nativeElement,
      data: {
          inputs: {
            ...this.composeConfig,
            apiCallback: (info)=> this.textAiService.suggestByTemplateSubject(info)
          },
          outputs: {
            loadingState: (event)=> {
              if(event && event.name == 'succeed') {

              }
            },
            onMessageSelected: (event)=> {
              console.log(pop.ref);
              if(event && event.message) {
                this.form.get('editorContent').patchValue(toDoc(event.message) as any)
              }
            }
          }
      },
    });
    // resizeObserver(pop.ref.overlay, {}, this.overlay)
  }
}
