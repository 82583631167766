import {NgModule} from '@angular/core';
import {MatIconModule} from "@angular/material/icon";
import {MatDividerModule} from "@angular/material/divider";
import {MatButtonModule} from "@angular/material/button";
import {FlexLayoutModule} from '@angular/flex-layout';
import {CommonModule} from "@angular/common";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatChipsModule} from '@angular/material/chips';
import {Gpt3SuggestMessageModule} from "@widgets/gpt3-ui/suggest-message/gpt3-suggest-message.module";
import {ComposeAiModule} from "@widgets/gpt3-ui/compose-ai/compose-ai.module";
import {MagicBoxWidgetModule} from "@widgets/magic-box/magic-box.widget.module";
import {MatDialogModule} from "@angular/material/dialog";
import {AppEditorModule} from "@app/app-editor/app-editor.module";
import {TemplateComponent} from "@pages/templates/template.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {IconModule} from "@visurel/iconify-angular";
import {MatTableModule} from "@angular/material/table";
import {MatAutocompleteModule} from "@angular/material/autocomplete";


@NgModule({
  declarations: [
    TemplateComponent,
  ],
  exports: [
    TemplateComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatDividerModule,
    MatSelectModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    Gpt3SuggestMessageModule,
    ComposeAiModule,
    MagicBoxWidgetModule,
    MatDialogModule,
    MatChipsModule,
    AppEditorModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    IconModule,
    MatTableModule,
    MatAutocompleteModule,
  ]
})
export class TemplateModule {
}
