<div class="NgxEditor__MenuItem--IconContainer" [class.NgxEditor__MenuItem--Active]="isActive || showPopup"
  [innerHTML]="icon | sanitizeHtml" (mousedown)="onMouseDown($event)" [title]="getLabel('insertImage')">
</div>

<!-- popup -->
<div *ngIf="showPopup" class="NgxEditor__Popup">
  <mat-slide-toggle (change)="changeMode()">{{mode == 'ai' ? 'Image AI' : 'Image' }}</mat-slide-toggle>

  <form *ngIf="mode == 'img'" class="NgxEditor__Popup--Form" [formGroup]="form" (ngSubmit)="insertLink($event)">

    <div class="NgxEditor__Popup--FormGroup">
      <div class="NgxEditor__Popup--Col">
        <label class="NgxEditor__Popup--Label">{{getLabel('url')}}</label>
        <input type="href" id="href" formControlName="src" autofocus autocomplete="off" />
        <div *ngIf="src.touched && src.invalid" class="NgxEditor__HelpText NgxEditor__HelpText--Error">
          {{ src.errors?.['pattern'] && 'Please enter valid url.' }}
        </div>
      </div>
    </div>

    <div class="NgxEditor__Popup--FormGroup">
      <div class="NgxEditor__Popup--Col">
        <label class="NgxEditor__Popup--Label">{{getLabel('altText')}}</label>
        <input type="text" formControlName="alt" autocomplete="off" />
      </div>
    </div>

    <div class="NgxEditor__Popup--FormGroup">
      <div class="NgxEditor__Popup--Col">
        <label class="NgxEditor__Popup--Label">{{getLabel('title')}}</label>
        <input type="text" formControlName="title" autocomplete="off" />
      </div>
    </div>

    <button type="submit" [disabled]="!form.valid || !form.dirty">{{getLabel('insert')}}</button>

  </form>
  <div *ngIf="mode == 'ai'">
    <textarea style="margin-top: 10px;margin-bottom: 10px;" rows="4" cols="50" [formControl]="imgAboutCtl" class="fw toolbar-input toolbar-input-border fc fr"></textarea>
    <div *ngIf="images && images.length" class="gallery" fxLayout="row" style="width: 500px">
      <div class="img-select images" (click)="selectImage(img.url)" style="cursor:pointer; min-width: 200px;padding: 15px;"  *ngFor="let img of images">
        <img class="fw" [src]="img.url"/>
      </div>
    </div>
    <button (click)="createImage()">{{imageIsLoading? 'Loading...' : 'Generate'}}</button>
  </div>
</div>
