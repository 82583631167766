import {
  Component, ElementRef,
  EventEmitter,
  HostListener, Input, OnDestroy, OnInit, Output,
} from '@angular/core';
import { EditorView } from 'prosemirror-view';
import { Subscription } from 'rxjs';
import {ControlValueAccessor} from "@angular/forms";
import { FormControl } from '@angular/forms';
import {isString} from "@helper-functions/is-string";
import {MenuService} from "@app/app-editor/ngx-editor/src/lib/modules/menu/menu.service";
import {ToggleCommands} from "@app/app-editor/ngx-editor/src/lib/modules/menu/MenuCommands";
import {VariableCommand} from "@app/app-editor/extensions/variable/variable.command";


@Component({
  selector: 'app-dropdown',
  template: `
    <div class="NgxEditor__Dropdown" style="height: 30px;" [class.icom-mode]="iconMode">
      <div class="NgxEditor__Dropdown--Text" [class.NgxEditor__Dropdown__Selected]="isSelected"
           [class.NgxEditor--Disabled]="isDropdownDisabled" (mousedown)="toggleDropdown($event)">
        <div *ngIf="!iconMode">
          {{activeItem && activeItem.label || group || 'Variable'}}
        </div>
        <ngx-toggle-command *ngIf="iconMode" (onSelected)="onCommandSelected($event)"
                            [disabledCtl]="true"
                            [toolbarItem]="command.label || 'align_center'"
                            [forceActive]="command.active"
                            [class]="''">
        </ngx-toggle-command>
      </div>

      <div class="NgxEditor__Dropdown--DropdownMenu" *ngIf="isDropdownOpen">
        <ng-container *ngIf="iconMode">
          <div  class="NgxEditor__Dropdown--Item" *ngFor="let item of items; trackBy: trackByIndex">
            <ngx-toggle-command (onSelected)="onCommandSelected($event)" [toolbarItem]="item.value" [class]="''">
            </ngx-toggle-command>
          </div>
        </ng-container>

        <ng-container  *ngIf="!iconMode">
          <div class="NgxEditor__Dropdown--Item" *ngFor="let item of items; trackBy: trackByIndex"
               (mousedown)="onClick($event, item)"
               [ngClass]="{'NgxEditor__Dropdown--Active': item === activeItem, 'NgxEditor--Disabled':disabledItems.includes(item)}">
            {{item && item.label}}
          </div>
        </ng-container>

      </div>
    </div>

  `,
  styles: [`
    .icom-mode {
      min-width: 30px !important;
      width: 30px !important;
      padding-top: 5px;
    }

    .NgxEditor__Dropdown.icom-mode .NgxEditor__Dropdown--Text::after {
      display: none !important;
    }

    .NgxEditor__Dropdown.icom-mode .NgxEditor__Dropdown--Item {
      padding: 4px;
    }
  `],
})
export class DropdownComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() editorView: EditorView;
  private updateSubscription: Subscription;
  ctl = new FormControl('');
  onChange = (v) => {}
  @Input() group: string;
  @Input() iconMode = false;
  @Input() items: { value: any, label: string, active?: boolean }[];
  @Output() onSelected = new EventEmitter();
  isDropdownOpen = false;
  command:{ value: any, label: string, active?: boolean } = {label: null, value: null}
  disabledItems: any[] = [];
  activeItem: any | null;

  constructor(
    private menuService: MenuService,
    private el: ElementRef,
  ) {

  }

  onCommandSelected({name, active}) {
    const alignment = this.items.find(item => item.value === name);
    alignment.active = active;
    if(active) {
      this.command = alignment;
    }
  }

  get isSelected(): boolean {
    return Boolean(this.activeItem || this.isDropdownOpen);
  }

  get isDropdownDisabled(): boolean {
    return this.disabledItems.length === this.items.length;
  }

  @HostListener('document:mousedown', ['$event.target']) onDocumentClick(target: Node): void {
    if (!this.el.nativeElement.contains(target) && this.isDropdownOpen) {
      this.isDropdownOpen = false;
    }
  }

  getName(key: string): string {
    return key;
  }

  toggleDropdown(e: MouseEvent): void {
    e.preventDefault();
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  trackByIndex(index: number): number {
    return index;
  }

  onClick(e: MouseEvent, item: any): void {
    e.preventDefault();
    // consider only left click
    if (e.button !== 0) {
      return;
    }
    this.ctl.patchValue(item);
    this.onChange(item);
    this.activeItem = item;
    this.isDropdownOpen = false;
    this.onSelected.emit(item);

    this.insertNewVariable(item);
  }

  insertNewVariable(item) {
    item.key = item.value;
    const command = new VariableCommand();
    const {state, dispatch} = this.menuService.editor.view;
    command.insert(this.menuService.editor.view, item as any)(state, dispatch);
  }

  ngOnInit(): void {
    if(!this.iconMode) {
      return;
    }
    this.updateSubscription = this.menuService.editor.update.subscribe((view: EditorView) => {
      const { state } = view;
      this.items.forEach((item: any)=> {
        const command = ToggleCommands[item.value];
        item.active = command.isActive(state);
        item.disabled = !command.canExecute(state);
        if(item.active) {
          this.command = item;
        }
      });
    });
  }

  ngOnDestroy(): void {
    try {
      this.updateSubscription.unsubscribe();
    } catch (e) {

    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(val: any): void {
    if(isString(val)) {
      this.activeItem = this.items.find((item)=>  item && item.value === val)
    } else if(val && val.value) {
      this.activeItem = this.items.find(item => item.value === val.value);
    }
  }
}
